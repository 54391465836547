import React, { useState } from 'react'

import Content from './Content'
import Application from './Application'

import { TabSection, Main, StyledButton } from './styles'

const tabs = {
  Role: Content,
  Application: Application,
}

function Switcher({ blok, appQuestions, privacyPolicy }) {
  const [curTab, setTab] = useState('Role')

  function changeTab(e) {
    setTab(e.target.value)
  }

  function moveToApplication() {
    setTab('Application')
    scrollTo(0, 120)
  }
  return (
    <section>
      <TabSection>
        {Object.keys(tabs).map(el => {
          return (
            <StyledButton
              isActive={curTab === el}
              onClick={changeTab}
              value={el}
              key={el}
            >
              {el}
            </StyledButton>
          )
        })}
      </TabSection>
      <Main>
        {React.createElement(tabs[curTab], {
          blok,
          appQuestions, 
          privacyPolicy,
          moveToApplication: moveToApplication,
        })}
      </Main>
    </section>
  )
}

export default Switcher
