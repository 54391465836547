/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Container, Row, Col } from 'components/Grid'
import { graphql } from 'gatsby'

import Layout from '../layout'
import Header from 'components/Navbar'
import Footer from 'components/Footer'
import Hero from 'components/Hero'
import Info from 'components/Role'
import Components from 'components/components.js'
import SEO from 'components/SEO'

const GATSBY_SMARTRECRUITER_AUTH_KEY = process.env.GATSBY_SMARTRECRUITER_AUTH_KEY

function JobPostEntry(props) {
  const [job, setJob] = useState('')
  const [appQuestions, setAppQuestions] = useState()
  const [privacyPolicy, setPrivacyPolicy] = useState()
  const appId = props.data.allSmartRecruiterJobs.edges[0].node.uuid

  const department = job.customField?.map(department => {
    if (department.fieldLabel === 'Department') {
      return department.valueLabel
    } else if (department.fieldLabel === 'Division') {
      return department.valueLabel
    }
  })

  const workLocation = job.customField?.map(workLocation => {
    if (workLocation.fieldLabel === 'Work Location') {
      return workLocation.valueLabel
    }
  })

  const state = {
    layout: JSON.parse(props.pageContext.layout.content),
    singleRole: props.pageContext.singleRole
      ? JSON.parse(props.pageContext.singleRole.content)
      : undefined,
  }

  const layout = state.layout
  const singleRole = state.singleRole
  const location = props.location
  const { lang, allLanguages } = props.pageContext
  const jobEntryBackground = state.singleRole.body[0].background_image
  const pageId = props.pageContext.jobId
  const url = `https://api.smartrecruiters.com/v1/companies/Avenues1/postings/${pageId}`

  useEffect(() => {
    axios.get(url).then(response => {
      setJob(response.data)
    })
  }, [])

  useEffect(() => {
    axios
      .get(`https://api.smartrecruiters.com/postings/${appId}/configuration`, {
        headers: {
          'X-SmartToken': GATSBY_SMARTRECRUITER_AUTH_KEY,
        },
      })
      .then(res => {
        setAppQuestions(res.data.questions)
        setPrivacyPolicy(res.data.privacyPolicies)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return (
    <Layout lang={props.pageContext.lang}>
      <SEO
        lang={lang}
        pageTitle={job.name}
        schemaLD={[layout.corporate_contact || {}]}
        location={location}
      />
      {layout.header_v2[0] && (
        <Header
          blok={layout.header_v2[0]}
          allLanguages={allLanguages || ['default']}
          lang={lang}
          location={location}
          jobEntryBackground={jobEntryBackground}
        />
      )}
      <Hero
        blok={{
          title: job.name,
          full_height: false,
          breadcrumbs_show_current: true,
          breadcrumbs_visible: true,
          breadcrumbs_override_current: job.name,
          heading_width: 'big',
          breadcrumbs_spacing_bottom: 11,
          padding_bottom: '0',
          padding_bottom_mb: '0',
          padding_top: 100,
          title_font_size: 'h3',
          title_font_size_mobile: 'h3',
        }}
        location={location}
      />
      <LocationInfo workLocation={workLocation} department={department} />
      <Info blok={job} appQuestions={appQuestions} privacyPolicy={privacyPolicy} />
      {singleRole?.body.map(el =>
        React.createElement(Components[el.component], {
          key: el._uid,
          blok: el,
          location,
          lang,
          allLanguages: allLanguages,
        }),
      )}
      {layout.footer[0] && <Footer blok={layout.footer[0]} />}
    </Layout>
  )
}

export default JobPostEntry

var LocationInfo = ({ workLocation, department }) => (
  <Container>
    <Row pb={64}>
      <Col width={[1, 1, 1]}>
        <h5>
          {workLocation} | {department}
        </h5>
      </Col>
    </Row>
  </Container>
)

export const query = graphql`
  query JobQuery($jobId: String) {
    ### Smart Recruiter
    allSmartRecruiterJobs(filter: {jobId: {eq: $jobId}}) {
      edges {
        node {
          uuid
        }
      }
    }
  }
`
