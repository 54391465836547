import styled from 'styled-components'
import { media } from 'utils'

export const StyledButton = styled.button`
  ${({ theme, isActive }) =>
    isActive ? theme.buttons.green : theme.buttons.tertiaryBordered};
  ${({ theme }) => theme.buttonsSize.big};
  border: 1px solid ${({ theme }) => theme.colors.neutral};
  margin: 20px;
`
export const TabSection = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  justify-content: center;
`
export const Main = styled.div`
  padding: 45px 0px;
  height: auto;
  ${media.greaterThan('md')`
    padding: 35px 50px;
  `}
`

export const StyledDiv = styled.div`
  p{
    font-size: 1em !important;
  }
`
