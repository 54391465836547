import React, { useState, useEffect } from 'react'

import { StyledDiv, StyledFrame, StyledFrameDiv, VideoSection } from './styles'

import { Container, Row, Col } from 'components/Grid'
import { StyledMarkdown as Markdown, CButton, Section } from 'components/Common'
import _unescape from 'lodash/unescape'

const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function Content({ blok, ...props }) {
  const [videoSrcOne, setVideoSrcOne] = useState('')
  const [videoSrcTwo, setVideoSrcTwo] = useState('')

  useEffect(() => {
    if (blok.jobAd?.sections?.videos) {
      const rawVideoOne = blok ? blok.jobAd?.sections?.videos?.urls[0] : ''

      if (rawVideoOne.includes('vimeo')) {
        let vimeoVideoOne = rawVideoOne.replace(
          'vimeo.com/',
          'player.vimeo.com/video/',
        )
        setVideoSrcOne(vimeoVideoOne)
      } else {
        const youtubeVideoOne = rawVideoOne.slice(-11)
        setVideoSrcOne('https://www.youtube.com/embed/' + youtubeVideoOne)
      }

      if (blok.jobAd?.sections?.videos.urls.length > 1) {
        const rawVideoTwo = blok ? blok.jobAd?.sections?.videos?.urls[1] : ''
        if (rawVideoTwo.includes('vimeo')) {
          let vimeoVideoTwo = rawVideoTwo.replace(
            'vimeo.com/',
            'player.vimeo.com/video/',
          )
          setVideoSrcTwo(vimeoVideoTwo)
        } else {
          const youtubeVideoTwo = rawVideoTwo.slice(-11)
          setVideoSrcTwo('https://www.youtube.com/embed/' + youtubeVideoTwo)
        }
      }
    }
  })

  return (
    <Section paddingBottom={170}>
      <Container>
        <Row>
          <Col width={[1]}>
            {blok ? (
              <StyledDiv>
                <h4>About Avenues</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blok.jobAd.sections.companyDescription.text,
                  }}
                />
                <h4>About the Role</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blok.jobAd.sections.jobDescription.text,
                  }}
                />
                <h4>About You</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blok.jobAd.sections.qualifications.text,
                  }}
                />
                <h4>Additional Information</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blok.jobAd.sections.additionalInformation.text,
                  }}
                />
                {(videoSrcOne === '' && videoSrcTwo === '') || IsChinaDeploy ? (
                  ' '
                ) : (
                  <VideoSection>
                    <StyledFrameDiv>
                      <StyledFrame
                        src={videoSrcOne}
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                      ></StyledFrame>
                    </StyledFrameDiv>

                    <StyledFrameDiv>
                      <StyledFrame
                        src={videoSrcTwo}
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                      ></StyledFrame>
                    </StyledFrameDiv>
                  </VideoSection>
                )}
              </StyledDiv>
            ) : (
              ''
            )}
            <CButton
              onClick={props.moveToApplication}
              type="green"
              size="big"
              css={'margin: 70px 20px 0 0 !important; font-size: 16px;'}
            >
              Apply for this role
            </CButton>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Content
