import styled from 'styled-components'

export const Fieldset = styled.fieldset`
  border: none;
  padding-bottom: 10px;
`

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px 0 !important;

  input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border: 1px solid rgb(233, 232, 231);
    border-radius: 60px;
    cursor: pointer;
  }

  input[type='radio']:checked {
    background-color: rgb(36, 142, 104) !important;
  }
`
export const CheckInput = styled.input``

export const ErrorMessage = styled.div`
 p {
    font-size: 12px;
    color: rgb(255, 49, 41);
 }
`

export const RadioLabel = styled.div`
  padding-left: 16px;
`
export const CheckLabel = styled.label`
font-family: Halyard-Book !important;
  padding-left: 16px;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 32px 0;

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border: 1px solid rgb(233, 232, 231);
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    background-color: rgb(36, 142, 104) !important;
  }
`

export const Input = styled.input`
  height: 48px;
  border: 1px solid rgb(233, 232, 231);
  background-color: white;
  width: 100%;
  padding: 1rem;
  outline: 0;
  &:focus {
    border-color: #004592;
  }
`
export const InputFile = styled.input.attrs({
  type: 'file',
})`
  border: none !important;
  background-color: white;
  width: 100%;
  padding: 1rem 0 1rem 0 !important;
  outline: 0;
`
export const InputRadio = styled.input.attrs({
  type: 'radio',
})`
  
`
export const InputSelect = styled.select`
  height: 58px;
  width: 100%;
  border: 1px solid rgb(233, 232, 231);
  background-color: white;
  padding: 1rem;
  font-family: Halyard-Book !important;
`

export const Legend = styled.legend`
  font-family: Halyard-Book !important;
  font-size: 22px;
  margin-bottom: 13px;
`

export const Label = styled.label`
  font-family: Halyard-Book !important;
  margin-top: 16px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: normal;
  display: block;

  sup {
    color: red;
    font-size: 16px;
  }

  a {
    font-weight: 600;
    border-bottom: 2px solid #248e68;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
  }
`

export const SubmitButton = styled.input.attrs({
  type: 'submit',
})`
  cursor: pointer;
  border: none !important;
  padding: 12px 32px !important;
  border-radius: 60px !important;
  transition: background-color 0.25s ease 0s;
  background-color: rgb(36, 142, 104) !important;
  color: rgb(255, 255, 255) !important;

  :hover {
    background-color: #165941 !important;
  }
`

export const StyledLink = styled.a`
  cursor: pointer;
  border: none !important;
  padding: 12px 32px !important;
  border-radius: 60px !important;
  transition: background-color 0.25s ease 0s;
  background-color: rgb(36, 142, 104) !important;
  color: rgb(255, 255, 255) !important;

  :hover {
    background-color: #165941 !important;
  }
`

export const SuccessMessage = styled.div`
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h5 {
    text-align: center;
  }
`

export const ChinaRedirect = styled.div`
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
