import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'

import { Container, Row, Col } from 'components/Grid'
import {
  Fieldset,
  Legend,
  Label,
  Input,
  InputFile,
  InputRadio,
  RadioWrapper,
  RadioLabel,
  CheckboxWrapper,
  SubmitButton,
  InputSelect,
  CheckInput,
  CheckLabel,
  ErrorMessage,
  SuccessMessage,
  StyledLink,
  ChinaRedirect,
} from './styles'

const GATSBY_SMARTRECRUITER_AUTH_KEY =
  process.env.GATSBY_SMARTRECRUITER_AUTH_KEY
const IsChinaDeploy = process.env.GATSBY_CHINA_DEPLOY

function Application({ blok, appQuestions, privacyPolicy }) {
  const [formSubmissionSuccessful, setFormSubmissionSuccessful] =
    useState(false)
  const appId = blok.uuid
  const jobId = blok.id
  const jobAdId = blok.jobAdId

  let pagePath = ''
  if (
    jobAdId === '165693cb-aa35-4608-ae58-8fe9a2049bac' ||
    jobAdId === 'e27b4691-27a2-4e3b-bd3d-72c8294990b4' ||
    jobAdId === '1dec4d00-f1e5-46e3-b347-8ab7ec671bab' ||
    jobAdId === 'c56eebeb-d865-48b9-a7ed-77a315e67b81' ||
    jobAdId === 'acfe7cd0-1066-4e67-9071-461238230aed' ||
    jobAdId === '186276e0-f39b-4eca-8700-121f3a33fd4e' ||
    jobAdId === 'c2a53dea-3648-43ee-9f41-4e287df3a5ba' ||
    jobAdId === '38cc93ac-3563-401e-a89c-e22b67359dc2' ||
    jobAdId === '4d58db2c-ab19-4cf4-a7f7-cd4bf7e27aa4' ||
    jobAdId === '7eb42321-65db-4ca3-9d9f-28bd3d06fd1d' ||
    jobAdId === 'bbe77b4a-a3ec-4701-91c5-2cad80997c83' ||
    jobAdId === '4182b4e2-dd21-48d0-b47f-7bcfade4a8c9' ||
    jobAdId === '2e1f97de-d018-40e9-8433-463debcae4ef' ||
    jobAdId === '4786bd31-7584-4240-8e15-155ac6cf6731' ||
    jobAdId === 'a7d2b7d1-a14b-4e43-b96e-9ebbd108ae18' ||
    jobAdId === '19e5a15e-13bb-4609-ac20-68d5d3b05e75' ||
    jobAdId === '72b52948-1afd-4099-a26a-842e5ae89367' ||
    jobAdId === 'f9ac7a85-6426-4af4-a013-b2147b803653' ||
    jobAdId === '0453ddb4-b2dc-4993-902c-051ae72e8c99' ||
    jobAdId === '067893ff-0d94-4470-bbb6-747cc36de94f' ||
    jobAdId === 'f49feeef-5989-444b-b3e7-235e3b94a3f5' ||
    jobAdId === '25ea00cf-f4f0-4a6b-80e5-19e756c21565' ||
    jobAdId === '1c5559ba-b7db-4c56-a17d-3225f7483542' ||
    jobAdId === '149388b0-df0b-406e-9aca-462590344ed3' ||
    jobAdId === '63d27940-1676-4adf-97b2-c043bedc817a' ||
    jobAdId === '41d4abf3-354c-4520-a006-a5386c0a4fcb' ||
    jobAdId === '9e80b620-f619-4345-abf8-f4df82478c60' ||
    jobAdId === '1ccb7afc-7710-425c-9dfc-26a2f15a459d' ||
    jobAdId === '0c7e287a-bf2e-426b-97e7-25f6df1def1e' ||
    jobAdId === 'ff619a66-8f18-4d54-896e-84736bc6f4b4' ||
    jobAdId === 'a754760f-01cd-418a-9657-89faf8b98bd8' ||
    jobAdId === '80426ec8-6c47-47c4-b911-0848487dd459' ||
    jobAdId === '3a956fd0-08a8-4e2a-b55f-5b44b46859bb' ||
    jobAdId === '5f8019ee-6f5a-4856-95b8-6c324d1f2f55' ||
    jobAdId === 'ced48c4e-0cd9-49d7-8ae3-3eaea9a9dc24' ||
    jobAdId === 'd2936b2c-66d1-4f15-98cd-997ed86f5bcc' ||
    jobAdId === 'dc50c8fe-a9b8-400d-9d99-e1b99616766e' ||
    jobAdId === 'b14047e0-39d2-472a-a963-a5ca48485fe3' ||
    jobAdId === 'ec6c9048-fa9d-4bbb-9026-2b21234aebe6' ||
    jobAdId === 'c0333537-778f-46e9-96c8-f8f1913fde4f' ||
    jobAdId === 'c69cb971-8c54-49cd-9047-554cc04b844e' ||
    jobAdId === 'e7eb832b-08b7-4365-97d7-4b1b1889b788' ||
    jobAdId === 'caf1732a-a9fa-4a35-b76b-8d7ebfdb847c' ||
    jobAdId === '0fe03a95-f945-4b7b-b598-b2d6287e115f' ||
    jobAdId === '5c0150b8-a697-43f0-832e-b74711aea5d9' ||
    jobAdId === 'e04e562c-698b-4381-9dfe-6efe8ec08627' ||
    jobAdId === '83e1254d-1b6a-46cf-b2ee-d1bc7ad72a0c' ||
    jobAdId === '93c2acbe-456e-4a90-9abf-1a0edd515860' ||
    jobAdId === '6bc43da3-a4b1-499d-9bc8-112a0b361067' ||
    jobAdId === 'c4d4b80e-7d39-42a5-9d48-7cf459f39b17' ||
    jobAdId === '8708df83-2868-4a94-a680-aaea21e18cdb' ||
    jobAdId === '791933e1-60e8-4ffd-8888-abc2dc4aa717' ||
    jobAdId === '295bbb53-8e0e-4bc1-abd7-2cb75d11f5d3' ||
    jobAdId === '0bf148ed-b70a-48d8-9bc0-68b60a2509fe' ||
    jobAdId === '37947c0d-fe30-4909-8cca-78a2a98017bc' ||
    jobAdId === 'ffca54c6-351f-46b8-85b4-1fa1b56c5fae' ||
    jobAdId === '9feae0af-8b9b-4ced-93fb-69a36f8e7214' ||
    jobAdId === 'bc73147a-7c34-4dc6-96d4-6c1cd8afafca' ||
    jobAdId === 'f4e251f5-38a3-4e0f-be07-9d0a93dea369' ||
    jobAdId === '9a629fc4-e97a-4098-a7dc-f9c32effbcaf' ||
    jobAdId === '7c3c7ff3-4ecb-4c81-8987-219b12452994' ||
    jobAdId === '0870809a-be9a-459c-8c0f-3daa587568c1' ||
    jobAdId === '73f824ec-6420-4b7e-a2b6-486694d990eb'
  ) {
    pagePath = jobId
  } else {
    pagePath = jobAdId
  }
  
  let jsonObject = appQuestions && appQuestions.map(JSON.stringify)
  let uniqueSet = new Set(jsonObject)
  let uniqueQuestions = Array.from(uniqueSet).map(JSON.parse)

  uniqueQuestions.map(question => {
    if (question.label.includes('[(definitions)]')) {
      const newLabel = question.label.split('[(definitions)]')
      question.label = newLabel[0]
    }
    if (question.fields.length > 0) {
      question.fields.map(field => {
        if (field.type === 'SINGLE_SELECT') {
          field.values.splice(0, 0, {
            id: '',
            label: 'Please select an answer',
          })
        }
      })
    }
    if (question.label.includes('upload') || question.label.includes('Upload')) {
      question.fields.map(field => {
        field.type = 'UPLOAD'
      })
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm()
  const watchEmail = watch('email', false)

  const convertBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = error => {
        reject(error)
      }
    })
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();
    data.resume.fileName = data.resume[0].name
    data.resume.mimeType = data.resume[0].type
    const rbase64 = await convertBase64(data.resume[0])
    const resumeBase64 = rbase64.split(',')
    data.resume.fileContent = '{' + resumeBase64[1] + '}'

    const filteredAttachments = data.attachments.filter(item => item)
    const removeEmptyAttachments = filteredAttachments.filter(item => (item.length > 0))

    const mapLoop = async () => {
      const promises = removeEmptyAttachments.map(async attachment => {
        attachment.fileName = attachment[0].name
        attachment.mimeType = attachment[0].type
        const content = await convertBase64(attachment[0])
        const fileInfo = content.split(',')
        attachment.fileContent = '{' + fileInfo[1] + '}'
        return attachment
      })

      const numFiles = await Promise.all(promises)
      data.attachments = numFiles
    }

    await mapLoop();

    await fetch(
      `https://api.smartrecruiters.com/postings/${appId}/candidates`,
      {
        method: 'POST',
        headers: {
          'X-SmartToken': GATSBY_SMARTRECRUITER_AUTH_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    )
      .then(response => {
        console.log(response)
      })
      .catch(err => {
        console.log(err.message)
      })
    setFormSubmissionSuccessful(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    reset();
  }

  return (
    <Container css="height: max-content;">
      <Row justifyContent="center">
        <Col width={[1, 1, 10 / 12]}>
          {!formSubmissionSuccessful && !IsChinaDeploy && (
            <Fieldset>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Legend>Please fill out the application</Legend>
                <Label>
                  First name<sup>*</sup>
                </Label>
                <Input {...register('firstName', { required: true })} />
                <ErrorMessage>
                  {errors.firstName && <p>This is a required field</p>}
                </ErrorMessage>
                <Label>
                  Last name<sup>*</sup>
                </Label>
                <Input {...register('lastName', { required: true })} />
                <ErrorMessage>
                  {errors.lastName && <p>This is a required field</p>}
                </ErrorMessage>
                <Label>
                  Email<sup>*</sup>
                </Label>
                <Input
                  type="email"
                  {...register('email', {
                    required: true,
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                <ErrorMessage>
                  {errors.email && <p>Please check your email address</p>}
                </ErrorMessage>
                <Label>
                  Confirm your email<sup>*</sup>
                </Label>
                <Input
                  type="email"
                  {...register('email_confirm', {
                    required: true,
                    validate: {
                      emailEqual: value =>
                        value === watchEmail || 'Email confirmation error!',
                    },
                  })}
                />
                <ErrorMessage>
                  {errors.email_confirm && <p>Email confirmation error!</p>}
                </ErrorMessage>
                <Label>
                  City<sup>*</sup>
                </Label>
                <Input {...register('location.city', { required: true })} />
                <ErrorMessage>
                  {errors.location?.city && <p>This is a required field</p>}
                </ErrorMessage>
                <Label>
                  State and/or Region<sup>*</sup>
                </Label>
                <Input {...register('location.region', { required: true })} />
                <ErrorMessage>
                  {errors.location?.region && <p>This is a required field</p>}
                </ErrorMessage>
                <Label>
                  Country<sup>*</sup>
                </Label>
                <Input {...register('location.country', { required: true })} />
                <ErrorMessage>
                  {errors.location?.country && <p>This is a required field</p>}
                </ErrorMessage>
                <Label>
                  Phone number<sup>*</sup>
                </Label>
                <Input
                  type="tel"
                  {...register('phoneNumber', {
                    required: true,
                    minLength: {
                      value: 10,
                      message: 'A phone number must be at least 10 numbers',
                    },
                  })}
                />
                <ErrorMessage>
                  {errors.phoneNumber && <p>Please check your phone number</p>}
                </ErrorMessage>
                <Label>
                  Resume<sup>*</sup>
                </Label>
                <InputFile
                  type="file"
                  {...register('resume', { required: true })}
                />
                <ErrorMessage>
                  {errors.resume && <p>This is a required field</p>}
                </ErrorMessage>
                <Label>
                  Please upload your customized cover letter<sup>*</sup>
                </Label>
                <InputFile
                  type="file"
                  {...register('attachments[0]', { required: true })}
                />
                <ErrorMessage>
                  {errors.attachments && <p>This is a required field</p>}
                </ErrorMessage>

                {uniqueQuestions &&
                  uniqueQuestions.map((question, index) => {
                    return (
                      <>
                        {question.fields.map((fields, i) => {
                          if (
                            fields.type === 'TEXTAREA' ||
                            fields.type === 'INPUT_TEXT'
                          ) {
                            return (
                              <>
                                <Label>
                                  {question.label}
                                  {fields.required === true ? <sup>*</sup> : ''}
                                </Label>
                                <Input
                                  type="hidden"
                                  value={question.id}
                                  {...register(`answers[${index}].id`)}
                                />

                                <Input
                                  id={`answers[${index}].records[0].fields.value[0]`}
                                  {...register(
                                    `answers[${index}].records[0].fields.value[0]`,
                                    {
                                      required: fields.required,
                                    },
                                  )}
                                />
                                <ErrorMessage>
                                  {errors?.answers?.length > 0 &&
                                    errors.answers[index] && (
                                      <p>This is a required field</p>
                                    )}
                                </ErrorMessage>
                              </>
                            )
                          }

                          if (fields.type === 'INFORMATION') {
                            return (
                              <>
                                <Label>
                                  {question.label}
                                  {fields.required === true ? <sup>*</sup> : ''}
                                </Label>
                                <Input
                                  type="hidden"
                                  value={question.id}
                                  {...register(`answers[${index}].id`)}
                                />

                                <Input
                                  type="hidden"
                                  id={`answers[${index}].records[0].fields.value[0]`}
                                  {...register(
                                    `answers[${index}].records[0].fields.value[0]`,
                                    {
                                      required: fields.required,
                                    },
                                  )}
                                />
                              </>
                            )
                          }

                          if (fields.type === 'UPLOAD') {
                            {
                              return (
                                <>
                                  <Label>
                                    {question.label}
                                    {fields.required === true ? (
                                      <sup>*</sup>
                                    ) : (
                                      ''
                                    )}
                                  </Label>
                                  <Input
                                    type="hidden"
                                    value={question.id}
                                    {...register(`answers[${index}].id`)}
                                  />

                                  <Input
                                    type="hidden"
                                    id={`answers[${index}].records[0].fields.value[0]`}
                                    {...register(
                                      `answers[${index}].records[0].fields.value[0]`,
                                      {
                                        required: fields.required,
                                      },
                                    )}
                                  />
                                  <InputFile
                                    type="file"
                                    {...register(`attachments[${index + 1}]`, {
                                      required: fields.required,
                                    })}
                                  />
                                </>
                              )
                            }
                          }

                          if (fields.type === 'RADIO') {
                            return (
                              <>
                                <Label>
                                  {question.label}
                                  {fields.required === true ? <sup>*</sup> : ''}
                                </Label>
                                <Input
                                  type="hidden"
                                  value={question.id}
                                  {...register(`answers[${index}].id`)}
                                />
                                {fields.values.map((value, i) => {
                                  return (
                                    <RadioWrapper>
                                      <InputRadio
                                        type="radio"
                                        value={value.id}
                                        {...register(
                                          `answers[${index}].records[0].fields.value[0]`,
                                          {
                                            required: fields.required,
                                          },
                                        )}
                                      />
                                      <RadioLabel>{value.label}</RadioLabel>
                                    </RadioWrapper>
                                  )
                                })}
                                <ErrorMessage>
                                  {errors?.answers?.length > 0 &&
                                    errors.answers[index] && (
                                      <p>This is a required field</p>
                                    )}
                                </ErrorMessage>
                              </>
                            )
                          }

                          if (fields.type === 'SINGLE_SELECT') {
                            return (
                              <>
                                {question.fields.length === 1 ? (
                                  <>
                                    <Input
                                      type="hidden"
                                      value={question.id}
                                      {...register(`answers[${index}].id`)}
                                    />
                                    <Label>
                                      {question.label}
                                      {fields.required === true ? (
                                        <sup>*</sup>
                                      ) : (
                                        ''
                                      )}
                                    </Label>
                                    <InputSelect
                                      {...register(
                                        `answers[${index}].records[0].fields.value[0]`,
                                        { required: fields.required },
                                      )}
                                    >
                                      {fields.values.map((value, i) => {
                                        return (
                                          <>
                                            <option value={value.id}>
                                              {value.label}
                                            </option>
                                          </>
                                        )
                                      })}
                                    </InputSelect>
                                    <ErrorMessage>
                                      {errors?.answers?.length > 0 &&
                                        errors.answers[index] && (
                                          <p>This is a required field</p>
                                        )}
                                    </ErrorMessage>
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      type="hidden"
                                      value={question.id}
                                      {...register(`answers[${index}].id`)}
                                    />
                                    <Label>
                                      {fields.label}
                                      {fields.required === true ? (
                                        <sup>*</sup>
                                      ) : (
                                        ''
                                      )}
                                    </Label>
                                    <InputSelect
                                      {...register(
                                        `answers[${index}].records[0].fields.${fields.id}[0]`,
                                        { required: fields.required },
                                      )}
                                    >
                                      {fields.values.map(value => {
                                        return (
                                          <>
                                            <option value={value.id}>
                                              {value.label}
                                            </option>
                                          </>
                                        )
                                      })}
                                    </InputSelect>
                                    <ErrorMessage>
                                      {errors?.answers?.length > 0 &&
                                        errors.answers[index] && (
                                          <p>This is a required field</p>
                                        )}
                                    </ErrorMessage>
                                  </>
                                )}
                              </>
                            )
                          }
                        })}
                      </>
                    )
                  })}
                <Label>
                  Please review the Avenues{' '}
                  <a href={privacyPolicy && privacyPolicy[1]?.url}>
                    Privacy Policy
                  </a>
                </Label>
                <ErrorMessage>
                  {errors.consent && (
                    <p>Please acknowledge our Privacy Policy</p>
                  )}
                </ErrorMessage>
                <CheckboxWrapper>
                  <CheckInput
                    type="checkbox"
                    value="true"
                    {...register('consent', { required: true })}
                  />
                  <CheckLabel for="consent">
                    By checking this box, you will declare that you read and
                    understand the privacy policy of Avenues
                  </CheckLabel>
                </CheckboxWrapper>

                <SubmitButton type="submit" />
              </form>
            </Fieldset>
          )}
          {formSubmissionSuccessful && (
            <SuccessMessage>
              <h5>
                You have successfully submitted your application to Avenues: The
                World School!
              </h5>
            </SuccessMessage>
          )}
          {IsChinaDeploy && (
            <ChinaRedirect>
              <h5>
                To apply for this position, please click below to go to our
                global site.
              </h5>
              <div>
                <StyledLink
                  href={`https://www.avenues.org/careers/open-roles/${pagePath}`}
                  target="_blank"
                >
                  Apply here
                </StyledLink>
              </div>
            </ChinaRedirect>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default React.memo(Application)
